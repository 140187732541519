<template>
  <CDropdown
    :caret="false"
    placement="bottom-end"
    in-nav
    class="c-header-nav-item d-md-down-none mx-2"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <CIcon :height="20" :content="language.flag" />
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center bg-light">
      <strong>Seleziona lingua</strong>
    </CDropdownHeader>

    <CDropdownItem
      class="d-block"
      v-for="entry in languages"
      :key="entry.title"
      @click="switchLocale(entry.language)"
    >
      <CIcon :height="15" :content="entry.flag" /> {{ entry.title }}
    </CDropdownItem>
  </CDropdown>
</template>
<script>
import { flagSet } from "@coreui/icons";

const languages = [
  { flag: flagSet.cifIt, language: "it", title: "Italiano" },
  { flag: flagSet.cifGb, language: "en", title: "English" },
  { flag: flagSet.cifDe, language: "de", title: "Deutsch" },
  { flag: flagSet.cifFr, language: "fr", title: "Français" },
];

function GetLanguage(locale) {
  var language = languages[1];
  for (var i = 0; i < languages.length; i++) {
    if (languages[i].language == locale) {
      language = languages[i];
    }
  }
  return language;
}
export default {
  name: "TheHeaderDropdownLang",
  // flagSet,
  data() {
    return {
      languages: languages,
      language: GetLanguage(this.$i18n.locale),
    };
  },
  methods: {
    // <---------------------------
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        this.language = GetLanguage(this.$i18n.locale);
      }
    },
  },
};
</script>
